import api from './api'
import {LOADING,LOADED,ERROR_MESSAGE, SUCCESS_MESSAGE} from './type'
import history from '../history';

export const fetchDrivers = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try{
        const response = await api(localStorage.getItem("token")).get("/get-drivers");
        if(response.status === 200)
        {
            dispatch({ type: LOADED });
            return response.data

        }
    }catch (err){
         if(err.response)
        {
            dispatch({ type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
        
        dispatch({type: ERROR_MESSAGE, payload: err.response ? err.response.data.message: history.push('/error')});
        dispatch({ type: LOADED });
    }
}
export const fetchSendDrivers = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try{
        const response = await api(localStorage.getItem("token")).get("/intransit/driver");
        if(response.status === 200)
        {
            dispatch({ type: LOADED });
            return response.data.drivers

        }
    }catch (err){
         if(err.response)
        {
            dispatch({ type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
        
        dispatch({type: ERROR_MESSAGE, payload: err.response ? err.response.data.message: history.push('/error')});
        dispatch({ type: LOADED });
    }
}
export const fetchDriverStatistics = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try{
        const response = await api(localStorage.getItem("token")).get("/get-drivers-statistic");
    
        if(response.status === 200)
        {
            dispatch({ type: LOADED });
            return response.data
        }
      
    }catch (err){
        if(err.response)
        {
            dispatch({ type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
        dispatch({type: ERROR_MESSAGE, payload: err.response ? err.response.data.message: history.push('/error')});
        dispatch({ type: LOADED });
    }
}
export const hasToPay = (delivery_agent) => async (dispatch) => {
    dispatch({ type: LOADING });
    try{
        const response = await api(localStorage.getItem("token")).get(`/has-to-pay?delivery_agent=${delivery_agent}`);
    
        if(response.status === 200)
        {
            dispatch({ type: LOADED });
            return response.data
        }
    }catch (err){
         if(err.response)
        {
            dispatch({ type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }

        dispatch({type: ERROR_MESSAGE, payload: err.response ? err.response.data.message: history.push('/error')});
        dispatch({ type: LOADED });
    }
}
export const receiveCollection = (collect_amount,delivery_agent) => async (dispatch) => {
    
    dispatch({ type: LOADING });
    const obj = {
    "collect_amount": collect_amount,
    "delivery_agent":delivery_agent,
    }



    try{
        const response = await api(localStorage.getItem("token")).patch("/collect-driver-collection",{...obj});
    
        if(response.status === 201)
        {
            dispatch({ type: LOADED });
            dispatch({type: SUCCESS_MESSAGE, payload: 'Successfully received. '});   
            const res = await api(localStorage.getItem("token")).get("/get-warehouse");
            if(res.status === 200){
                localStorage.setItem("ac_balance", JSON.stringify(res?.data?.account_balance))
            }
            return response.status

        }
    }catch (err){
         if(err.response)
        {
            dispatch({ type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
        
        dispatch({type: ERROR_MESSAGE, payload: err.response ? err.response.data.message: history.push('/error')});
        dispatch({ type: LOADED });
    }
}
