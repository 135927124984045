import api from './api'
import {LOADING,LOADED,ERROR_MESSAGE, SUCCESS_MESSAGE} from './type'
import history from '../history';

export const fetchTPPaymentPending = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try{
        const response = await api(localStorage.getItem("token")).get("/eCourier/payment/pending");
        if(response.status === 200)
        {
            dispatch({ type: LOADED });
            return response.data

        }
    }catch (err){
         if(err.response)
        {
            dispatch({ type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
        
        dispatch({type: ERROR_MESSAGE, payload: err.response ? err.response.data.message: history.push('/error')});
        dispatch({ type: LOADED });
    }
}

export const CollectTPPayment = (collect_amount) => async (dispatch) => {
    dispatch({ type: LOADING });
    try{
        const response = await api(localStorage.getItem("token")).post("/eCourier/payment/collect",{'collect_amount': collect_amount});
        if(response.status === 201)
        {
            dispatch({ type: LOADED });
            return response.data

        }
    }catch (err){
         if(err.response)
        {
            dispatch({ type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
        
        dispatch({type: ERROR_MESSAGE, payload: err.response ? err.response.data.message: history.push('/error')});
        dispatch({ type: LOADED });
    }
}
export const codCollection = (start,end) => async (dispatch) => {
    dispatch({ type: LOADING });
    try{
        const response = await api(localStorage.getItem("token")).get(`/report/third-party/cod-collection?startDate=${start}&endDate=${end}`);
        
        if(response.status === 200)
        {
            dispatch({ type: LOADED });
            return response.data?.report
        }
    }catch (err){
         if(err.response)
        {
            dispatch({ type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
        
        dispatch({type: ERROR_MESSAGE , payload: err.response ? err.response.data.message: history.push('/error')});
        dispatch({ type: LOADED });
    }
}