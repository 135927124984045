import React from 'react'
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from '../pages/dashboard';
import Parcels from '../pages/parcels';
import PendingParcels from '../pages/parcels/regularDelivery/normalOrders/pending';
import ConfirmedParcels from '../pages/parcels/regularDelivery/normalOrders/confirmed';
import PickupParcels from '../pages/parcels/regularDelivery/normalOrders/pickup';
import AssignParcels from '../pages/parcels/regularDelivery/normalOrders/assign';
import OutForDeliveryParcels from '../pages/parcels/regularDelivery/normalOrders/outForDelivery';
import WarehouseParcels from '../pages/parcels/regularDelivery/normalOrders/warehouseParcel';
import PickupReturnParcels from '../pages/parcels/regularDelivery/returnOrder/returnPickup';
import UpComingReturn from '../pages/parcels/regularDelivery/returnOrder/upComingReturn';
import PickupHoldParcels from '../pages/parcels/regularDelivery/returnOrder/holdPickup';
import warehouseReturn from '../pages/parcels/regularDelivery/returnOrder/warehouseReturn.jsx';
import TransitPendingParcels from '../pages/parcels/regularDelivery/transitOrder/pending'
import SendParcels from '../pages/parcels/regularDelivery/transitOrder/send'
import ODDPendingParcels from '../pages/parcels/onDayDelivery/pending'
import AssignODDParcels from '../pages/parcels/onDayDelivery/assign'
import ODDProcessingParcels from '../pages/parcels/onDayDelivery/processing'
import SendParcelsToTP from '../pages/parcels/thirdParty/sendParcel'
import RiderStatc from '../pages/rider'
import collectAmount from '../pages/rider/collectAmount';
import TransactionList from '../pages/transaction';
import Report from '../pages/report';
import processingParcels from '../pages/parcels/thirdParty/processingParcels';
import CollectReturnParcels from '../pages/parcels/thirdParty/collectReturn';
import tpCollectAmount from '../pages/parcels/thirdParty/tpCollectAmount';
import tpReport from '../pages/report/tpReport';
const Switcher = () => {
    return ( 
        <div style={{padding: '10px'}}>
            <Switch>
                <Route path="/parcels" component={Parcels} />
                <Route path="/parcels-pending" component={PendingParcels} />
                <Route path="/parcels-confirmed" component={ConfirmedParcels} />
                <Route path="/parcels-pickup" component={PickupParcels} />
                <Route path="/parcels-assign" component={AssignParcels} />
                <Route path="/parcels-out-for-delivery" component={OutForDeliveryParcels} />
                <Route path="/parcels-warehouse" component={WarehouseParcels} />

                <Route path="/parcels-return-pickup" component={PickupReturnParcels} />
                <Route path="/parcels-upcoming-return" component={UpComingReturn} />
                <Route path="/parcels-hold-pickup" component={PickupHoldParcels} />
                <Route path="/parcels-warehouse-return" component={warehouseReturn} />

                <Route path="/parcels-transit-pending" component={TransitPendingParcels} />
                <Route path="/parcels-send" component={SendParcels} />

                <Route path="/parcels-odd-pending" component={ODDPendingParcels} />
                <Route path="/parcels-odd-assign" component={AssignODDParcels} />
                <Route path="/parcels-odd-processing" component={ODDProcessingParcels} />

                <Route path="/parcel-send-tp" component={SendParcelsToTP} />
                <Route path="/parcel-processing-tp" component={processingParcels} />
                <Route path="/parcel-return-collect-tp" component={CollectReturnParcels} />
                <Route path="/collect-amount-tp" component={tpCollectAmount} />
                <Route path="/report-tp" component={tpReport} />
                
                <Route path="/riders" component={RiderStatc} />
                <Route path="/collect-amount" component={collectAmount} />

                <Route path="/transactions" component={TransactionList} />

                <Route path="/reports" component={Report} />

                <Redirect to="/parcels" from="/" />
            </Switch>
        </div>
         );
}
 
export default Switcher;