import api from './api'
import {LOADING,LOADED,ERROR_MESSAGE, SUCCESS_MESSAGE} from './type'
import history from '../history';

export const fetchWarehouse = () => async (dispatch) => {
    dispatch({ type: LOADING });
    try{
        const response = await api(localStorage.getItem("token")).get("/list-of-warehouse");
        if(response.status === 200)
        {
            dispatch({ type: LOADED });
            return response.data.warehouse;

        }
    }catch (err){
         if(err.response)
        {
            dispatch({ type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
        
        dispatch({type: ERROR_MESSAGE, payload: err.response ? err.response.data.message: history.push('/error')});
        dispatch({ type: LOADED });
    }
}