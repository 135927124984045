import history from "../history";
import {
  LOADED,
  LOADING,
  ERROR_MESSAGE,
  SUCCESS_MESSAGE
} from "./type";

import api from "./api";

export const signin = (user_id, password) => async (dispatch) => {
  // =============================loading 
  
  dispatch({ type: LOADING });

  try {
    const response = await api().post(`/signin`, {
      user_id: 'WHM'+user_id,
      password: password, 
    });
    if (response.status === 200) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("name", response.data.name);
      localStorage.setItem("user_id", user_id);
      localStorage.setItem("email", response.data.email);
      localStorage.setItem("thirdparty", response.data.tp);
      const res = await api(response.data.token).get("/get-warehouse");
      if(res.status === 200){
        
        localStorage.setItem("warehouse_name", res.data?.name);
        localStorage.setItem("warehouse", JSON.stringify(res?.data))
        localStorage.setItem("ac_balance", JSON.stringify(res?.data?.account_balance))
      }
// // ============================================shop
//     //   let shops = null;
//     //   if(response.data ){
//     //     const token= response.data.token;
//     //     shops = await api().get(`/get-shops`,{
//     //       headers: {
//     //         'authorization': `Bearer ${token}`
//     //       }
//     //     });
//     //     console.log(shops)
        
//     //   if(shops.status===200){
//     //     localStorage.setItem("shops", JSON.stringify(shops.data));
//     //   }
//     //   // dispatch({ type: FETCH_ORDERS, payload: orders.data });
//     //   }
    
      history.replace("/dashboard");
      dispatch({ type: LOADED });
//     } else {
      dispatch({ type: LOADED });
      
    }
  } catch (err) {
     if(err.response)
        {
            dispatch({ type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
    dispatch({ type: LOADED });
    dispatch({
      type: ERROR_MESSAGE,
      payload: err.response ? err.response.data.message : history.push('/error'),
    });
    
  }
};

export const signOut = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try{
      const response = await api(localStorage.getItem("token")).patch("/signout");
      if(response.status === 201)
      {
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: LOADED });
  }catch (err){
       if(err.response)
        {
            dispatch({ type: LOADED });
            if(err.response.status === 401){ localStorage.clear(); history.push('/login') }
        }
      dispatch({type: ERROR_MESSAGE, payload: err.response ? err.response.data.message: history.push('/error')});
      dispatch({ type: LOADED });
  }
}


