import { ArrowUpOutlined } from '@ant-design/icons';
import { BackTop } from 'antd';
import React, {useEffect, useState } from 'react'
import { connect } from 'react-redux';
import Alert from '../components/alert';
import NavBar from '../components/navBar';
import Spinner from '../components/spinner';
import history from '../history';

import { Content, Footer } from 'antd/lib/layout/layout';
import Switcher from './switcher'

const style = {
  height: 40,
  width: 40,
  borderRadius: 4,
  backgroundColor: '#e91010',
  color: '#fff',
  textAlign: 'center',
  fontSize: 18,
};
const Layout = ({loader,error, success}) => {
    useEffect(() => {
        if(!localStorage.getItem('token')){
            localStorage.clear();
            history.push('./login') 
         }
    }, [])
    
    return ( 
        <>
        {loader ? <Spinner /> : null}
        
        {error ? <Alert value={error} type="error" /> : null}
        {success ? <Alert value={success} type="success" /> : null}
        <NavBar />
        <Switcher />
        <BackTop>
          <div style={style}> <ArrowUpOutlined /></div>
        </BackTop>
        {/* === Footer === */}
        
        </>
     );
}
const mapStateToProps = (state) => {
    return{
      
      loader: state.helpers.loading,
      error: state.helpers.error,
      success: state.helpers.success,
     
    }
  }
export default connect(mapStateToProps)(Layout);