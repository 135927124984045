import React, { Component } from 'react'

import { Button, Col, Empty, Form, Input, Row, Select, Space, Table, Tag } from 'antd';
import { connect } from 'react-redux';
import {fetchSendOrder,sendTOrder} from '../../../../action/parcel'
import {fetchWarehouse} from '../../../../action/warehouse'
import {fetchSendDrivers} from '../../../../action/drivers'
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import Header from '../../../../components/header';
const { Option } = Select;
class SendParcels extends Component {
  state = { 
    
    searchText: '',
    searchedColumn: '',
    data: [],
    drivers: [],
    warehouses: [],
    rider: '',
    warehouse: ''
   } 
    componentDidMount = () => {
        this.getData()
        this.getDriver()
        this.getWarehouse()
    };
    getData = async () => {
        const res = await this.props.fetchSendOrder()
        this.setState({data: res})
    }
    getDriver = async () => {
      const res = await this.props.fetchSendDrivers()
      this.setState({drivers: res})
    }
    getWarehouse = async () => {
      const res = await this.props.fetchWarehouse()
      this.setState({warehouses: res})
    }
   
  
    getColumnSearchProps =  dataIndex => ({
        filterDropdown:  ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={node => {
                this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => {setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
                this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
                });}}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
            />
        </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
        if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
        }
        },
        render: text =>
        this.state.searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });
    
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
        });
    };

    createData = (order_id,store_info,customer_info,delivery_warehouse,order_time,action) => {
        return {order_id,store_info,customer_info,delivery_warehouse, order_time,action};
    };
    
    createOption = (label,value) => {
      return {label,value};
  };

    makeLocalTime = (date) => {
        let mainDate = date.split('T')[0];
        let hours = date.split('T')[1].split(':')[0];
        let minutes = date.split('T')[1].split(':')[1];
        var am = true;
        if (hours > 12) {
            am = false;
            hours -= 12
        } else if (hours == 12) {
            am = false;
        } else if (hours == 0) {
            hours = 12
        }
        return mainDate + " , " + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleSubmit = async (order_id) => {
      const { rider, warehouse } = this.state;
      if(warehouse === ''){
        alert("Please Select Warehouse");
      } 
      else if(rider === ''){
        alert("Please Select Driver");
      }
      else {
        const code = await this.props.sendTOrder(order_id, warehouse,rider);
        if(code === 201){
          // this.setState({rider: ''})
          // this.setState({warehouse: ''})
          this.getData()
        }
      }
    }
  render() { 
    
    const columns = [
      {
        title: 'Order ID',
        dataIndex: 'order_id',
        key: 'order_id',
        width: '15%',
        ...this.getColumnSearchProps('order_id'),
      },
      {
        title: 'Store Info',
        dataIndex: 'store_info',
        key: 'store_info',
        width: '20%',
        ...this.getColumnSearchProps('store_info'),
      },
      {
        title: 'Customer Info',
        dataIndex: 'customer_info',
        key: 'customer_info',
        width: '20%',
        ...this.getColumnSearchProps('customer_info'),
      },
      {
        title: 'Delivery Warehouse',
        dataIndex: 'delivery_warehouse',
        key: 'delivery_warehouse',
        width: '10%',
      },
      {
        title: 'Order Time',
        dataIndex: 'order_time',
        key: 'order_time',
        width: '12%'
      },
      
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: '10%'
      }
    ];
    const {data,drivers,warehouses} = this.state
    const rows = [...
      data
        ? data?.map((item,id) => this.createData(
            item.order_id
            , 
            item?.shop?.shop_id + '--' + item?.shop?.name + '--' + item?.shop?.mobile_number
            ,
              item?.recipient_name + '--' + item?.recipient_mobile_number + '--' + item?.recipient_address
            ,
                <Tag color="#f50" >{item?.delivery_warehouse?.name}</Tag>
            ,
              this.makeLocalTime(item?.created_at)
            , 
            <Button size="large" type="primary" onClick={() => this.handleSubmit(item.order_id)} >Send</Button>
            
            
          ))
        : "",
    ];
    const driverOptions = [
      drivers
        ? drivers?.map((item) =>
            this.createOption(
              `${item.mobile_number}----` + item.name,
              item.user_id
            )
          )
        : null,
    ];
    const warehouseOptions = [
      warehouses
        ? warehouses?.map((item) =>
            this.createOption(
              `${item.warehouse_id}----` + item.name,
              item.warehouse_id
            )
          )
        : null,
    ];
    return (
      <>
        
        <Header title="Send Parcels" subtitle="Transit Parcel" name="transit_order"/>
        <br />
          <Form>
            <Row>
              <Col xs={11}>
                <Form.Item label="Select a warehouse" name="warehouse">
                <Select
                style={{width: '100%'}}
                showSearch
                placeholder="Select a warehouse"
                optionFilterProp="children"
                onChange={(e) => this.setState({warehouse: e})}
                value={this.state.rider}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {warehouseOptions[0]? 
                  warehouseOptions[0]?.map((item,id) => (
                  <Option key={id} value={item?.value}>{item?.label}</Option>
                ))
              :null}
                </Select>
                </Form.Item>
              </Col>
              <Col xs={1}></Col>
              <Col xs={11}>
                <Form.Item label="Select a rider"  name="rider">
                  <Select
                  style={{width: '100%'}}
                  showSearch
                  placeholder="Select a rider"
                  optionFilterProp="children"
                  onChange={(e) => this.setState({rider: e})}
                  value={this.state.rider}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {driverOptions[0]? 
                    driverOptions[0]?.map((item,id) => (
                    <Option key={id} value={item?.value}>{item?.label}</Option>
                  ))
                :null}
                  </Select>
                </Form.Item>
              </Col>
              
            </Row>
          </Form>
            {rows?.length > 0
            ? 
                <Table columns={columns} dataSource={rows} scroll={{ x: 1000 }} sticky  />
            : 
            <Empty />}
      </>
    );
  }
}

export default connect(null,{fetchSendOrder,sendTOrder,fetchWarehouse,fetchSendDrivers})(SendParcels);