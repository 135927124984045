import React, { Component } from 'react'

import { Button, Col, Form, Grid, Input, Row, Select , Table } from 'antd';
import { connect } from 'react-redux';
import {fetchHoldParcel,pickupHoldOrder} from '../../../../action/parcel'
import {fetchDrivers} from '../../../../action/drivers'
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import Header from '../../../../components/header';

const { Option } = Select;




// ============================================
class PickupHoldParcels extends Component {
  state = { 
    searchText: '',
    searchedColumn: '',
    data: null,
    weight: '',
    r_no: '',
    drivers: '',
    rider: '',
   } 
    componentDidMount = () => {
        // this.getData()
        this.getDrivers()
    };
    // getData = async () => {
    //     const res = await this.props.fetchConfirmed()
    //     this.setState({data: res})
    // }
    getDrivers = async () => {
      const res = await this.props.fetchDrivers()
      this.setState({drivers: res})
    }
   
    
    createOption = (label, value) => {
      return { label, value };
    };

    getColumnSearchProps =  dataIndex => ({
        filterDropdown:  ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={node => {
                this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => {setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
                this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
                });}}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
            />
        </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
        if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
        }
        },
        render: text =>
        this.state.searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });
    
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
        });
    };

    createData = (order_id,store_info,customer_info,order_time,action) => {
        return {order_id,store_info,customer_info, order_time,action};
    };

    makeLocalTime = (date) => {
        let mainDate = date.split('T')[0];
        let hours = date.split('T')[1].split(':')[0];
        let minutes = date.split('T')[1].split(':')[1];
        var am = true;
        if (hours > 12) {
            am = false;
            hours -= 12
        } else if (hours == 12) {
            am = false;
        } else if (hours == 0) {
            hours = 12
        }
        return mainDate + " , " + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleSubmit = async (order_id) => {
      const {rider} = this.state
      
      if(!rider){
        alert('Select Driver')
      }
     
      else{
        const res = await this.props.pickupHoldOrder(order_id,rider)
        if(res === 201){
            this.setState({rider: '', data: null})
        }
      }
   
    }
    onDriverChange = async (e) => {
      this.setState({rider: e})
    }
    onFinish = async ({rider,order_id}) => {
      const res = await this.props.fetchHoldParcel(order_id,rider)
      this.setState({data: res,rider: rider})

    };
  render() { 
    
    const columns = [
      {
        title: 'Order ID',
        dataIndex: 'order_id',
        key: 'order_id',
        width: '15%',
        ...this.getColumnSearchProps('order_id'),
      },
      {
        title: 'Store Info',
        dataIndex: 'store_info',
        key: 'store_info',
        width: '20%',
      },
      {
        title: 'Customer Info',
        dataIndex: 'customer_info',
        key: 'customer_info',
        width: '20%',
      },
      {
        title: 'Order Time',
        dataIndex: 'order_time',
        key: 'order_time',
        width: '12%'
      },
      
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: '10%'
      }
    ];
    const {data, drivers} = this.state
    const rows = [
      data
        ? this.createData(
            data.order_id,
            data?.shop?.name + '--' + data?.shop?.mobile_number + '--' + data?.shop?.address
            ,
              data?.recipient_name + '--' + data?.recipient_mobile_number + '--' + data?.recipient_address
           
           ,
              this.makeLocalTime(data?.created_at)
            , 
            <Button size="small" type="primary" onClick={() => this.handleSubmit(data.order_id)} >Pickup</Button>
            
            
          )
        : "",
    ];
    const options = [
      drivers
        ? drivers?.map((item) =>
            this.createOption(
              `${item.mobile_number}----` + item.name,
              item.user_id
            )
          )
        : null,
    ];
    return (
      <>
        
        <Header title="Pickup Hold Parcels" subtitle="Return Parcels" name="return_order" />
        <br />
        <Form 
        onFinish={this.onFinish}
        >
          <Row>
          
            
          <Col xs={10}>
            <Form.Item
              label="Select Pickup Rider"
              name="rider"
              rules={[
                {
                  required: true,
                  message: 'Please select a rider',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select a rider"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {options[0]? 
                options[0]?.map((item,id) => (
                  <Option value={item?.value}>{item?.label}</Option>
                ))
              :null}
              </Select>
              </Form.Item>
          </Col>
          <Col xs={10}>
          <Form.Item
              label="Order ID"
              name="order_id"
              rules={[
                {
                  required: true,
                  message: 'Please enter order id',
                },
              ]}
            >
            <Input />
            </Form.Item>
          </Col>
          <Col xs={4}>
            <Button htmlType='submit'>Search</Button>
          </Col>
          
        </Row>
        </Form>
       
        
        <br />
            {data
            ? 
                <Table columns={columns} dataSource={rows} scroll={{ x: 1000 }} sticky  />
            : 
            null}

      </>
    );
  }
}

export default connect(null,{fetchHoldParcel,fetchDrivers,pickupHoldOrder})(PickupHoldParcels);