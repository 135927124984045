import React, { Component } from 'react'

import { Button, Col, Empty, Input, Row, Space, Table, Tag } from 'antd';
import { connect } from 'react-redux';
import {fetchUpcomingReturn} from '../../../../action/parcel'
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import Header from '../../../../components/header';
class UpComingReturn extends Component {
  state = { 
    
    searchText: '',
    searchedColumn: '',
    data: [],
   } 
    componentDidMount = () => {
        this.getData()
    };
    getData = async () => {
        const res = await this.props.fetchUpcomingReturn()
        this.setState({data: res})
    }
   
  
    getColumnSearchProps =  dataIndex => ({
        filterDropdown:  ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
            ref={node => {
                this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => {setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
                this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
                });}}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ marginBottom: 8, display: 'block' }}
            />
            {/* <Space>
            <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                confirm({ closeDropdown: false });
                this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                });
                }}
            >
                Filter
            </Button>
            </Space> */}
        </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
        if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
        }
        },
        render: text =>
        this.state.searchedColumn === dataIndex ? (
            <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
    });
    
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
        });
    };

    createData = (key,return_order_id,store_info,customer_info,hold_by_agent,state,details,order_time) => {
        return {key,return_order_id,store_info,customer_info,hold_by_agent,state, details,order_time};
    };

    makeLocalTime = (date) => {
        let mainDate = date.split('T')[0];
        let hours = date.split('T')[1].split(':')[0];
        let minutes = date.split('T')[1].split(':')[1];
        var am = true;
        if (hours > 12) {
            am = false;
            hours -= 12
        } else if (hours == 12) {
            am = false;
        } else if (hours == 0) {
            hours = 12
        }
        return mainDate + " , " + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };


  render() { 
    
    const columns = [
      {
        title: 'New Order ID',
        dataIndex: 'key',
        key: 'key',
        width: '15%',
        ...this.getColumnSearchProps('key'),
      },
      {
        title: 'Old Order ID',
        dataIndex: 'return_order_id',
        key: 'return_order_id',
        width: '15%',
      },
      {
        title: 'Store Info',
        dataIndex: 'store_info',
        key: 'store_info',
        width: '20%',
        ...this.getColumnSearchProps('store_info'),
      },
      {
        title: 'Customer Info',
        dataIndex: 'customer_info',
        key: 'customer_info',
        width: '20%',
        ...this.getColumnSearchProps('customer_info'),
      },
      {
        title: 'Hold By',
        dataIndex: 'hold_by_agent',
        key: 'hold_by_agent',
        width: '10%',
      },
      {
        title: 'state',
        dataIndex: 'state',
        key: 'state',
        width: '10%',
      },
      {
        title: 'Order Time',
        dataIndex: 'order_time',
        key: 'order_time',
        width: '12%',
      }
    ];
    const {data} = this.state
    const rows = [...
      data
        ? data?.map((item,id) => this.createData(
              item.order_id
            ,
              item?.return_order_id?.order_id
            , 
              item?.shop?.name + '(' + item?.shop?.mobile_number +')'
            ,
              item?.recipient_name + '(' + item?.recipient_mobile_number + ')'
            ,
              item?.hold_by_agent?.name
              // item?.hold_by_agent?.user_id + '--' + item?.hold_by_agent?.name + '--' + item?.hold_by_agent?.mobile_number
            ,
              <Tag color="blue">{item?.state}</Tag>
            ,
            
              <Row gutter={12}>
                <Col xs={8}>
                  <h3>Shop</h3>
                    <p style={{margin: '2px 0px'}}>{item?.shop?.shop_id}</p>
                    <p style={{margin: '2px 0px'}}>{item?.shop?.name}</p>
                    <p style={{margin: '2px 0px'}}>{item?.shop?.mobile_number}</p>
                    <p style={{margin: '2px 0px'}}>{item?.shop?.address}</p>
                </Col>
                <Col xs={8}>
                  <h3>Customer</h3>
                  <p style={{margin: '2px 0px'}}>{item?.recipient_name}</p>
                  <p style={{margin: '2px 0px'}}>{item?.recipient_mobile_number}</p>
                  <p style={{margin: '2px 0px'}}>{item?.recipient_address}</p>
                </Col>
                <Col xs={8}>
                  <h3>Rider (Hold by)</h3>
                  <p style={{margin: '2px 0px'}}>{item?.hold_by_agent?.user_id}</p>
                  <p style={{margin: '2px 0px'}}>{item?.hold_by_agent?.name}</p>
                  <p style={{margin: '2px 0px'}}>{item?.hold_by_agent?.mobile_number}</p>
                </Col>
              </Row>
            ,
              this.makeLocalTime(item?.created_at)
            , 
           
            
          ))
        : "",
    ];
    
    return (
      <>
        
        <Header title="Upcoming Parcels" subtitle="Return Parcel" name="return_order" />
            {rows?.length > 0
            ? 
                <Table columns={columns} dataSource={rows} scroll={{ x: 1000 }} sticky 
                  rowkey={(record) => console.log(record.key)} 
                  expandable={{
                    expandedRowRender: record => <p style={{ margin: 0 }}>{record.details}</p>,
                    expandRowByClick: true
                    
                  }}
                />
            : 
            <Empty />}
      </>
    );
  }
}

export default connect(null,{fetchUpcomingReturn})(UpComingReturn);