import styled from "styled-components";


export const InvoiceContainer = styled.div`
    /* margin: 15px; */
    
    width: 110mm;
    height: 82mm;
    color: #000;
    padding: 10px ;
    margin-bottom: 2px ;
    /* background-color: red; */
    p{
        font-size: 10px;
        font-weight: bold;
        color: #000;
        padding: 0px ;
        margin: 0px ;
    }
    span{
        font-size: 11px;
    }
    img{
        width: 105px;
    }
    h5{
        border-bottom: 1px dotted black;
        margin: 0px ;
    }
    
`;