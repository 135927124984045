import React, { Component } from "react";
import styled from 'styled-components';
import { resetMessage } from "../action/reset";
import { connect } from "react-redux";
const Msg = styled.div`
  position: fixed;
  right: -60px;
  top:0px;
  width: 400px;
  animation: mymove 1s  ;
  z-index: 100 ;

  @keyframes mymove {
   0%{top: -100px}
   
  
   
   100%{top: 0px;}

}
@media (max-width: 910px) {
  top: 0px;
  right: -60px;
  /* left: 10px; */
  

  @keyframes mymove {
   0%{right: -120px}

   50% {right: 0px;}
   
   100%{right: -60px;}

}
}

`;
class MessageAlert extends Component {
  state = {
    show: false,
    message: null,
  };
  handleShow = () => {
    this.setState({ show: !this.state.show });
  };
  componentDidMount() {
    this.setState({ message: this.props.value });
    setTimeout( () =>{ this.props.resetMessage()}, 3000);
  }
  render() {
    const { type } = this.props;
    return (
      <Msg>
        {type === 'success' 
        ? 
        <p className="p-2 bg-white text-center text-green-700 font-bold" onClick={() => {this.props.resetMessage()}}>{this.state.message}</p>
          
        : 
        <p className="p-2 bg-red-500 text-center text-white font-bold" onClick={() => {this.props.resetMessage()}}>{this.state.message}</p>
          
        }{/* <Alert style={{width: '80%', padding: '0px 10px', marginTop: '10px'}}  severity={type} variant="filled" onClose={() => {this.props.resetMessage()}}>
            {this.state.message}
          </Alert> */}
      </Msg>
    );
  }
}

export default connect(null, { resetMessage })(MessageAlert);
