import { Button, Card, Col, Form, Row, Select, DatePicker } from 'antd';
import React, { useEffect, useState } from 'react'
import Header from '../../components/header';
import {codCollection} from '../../action/thirldParty'
import { connect } from 'react-redux';
// import ReactExport from "react-export-excel";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable'
import moment from 'moment';
const { Option } = Select;
const { RangePicker } = DatePicker;

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelColumn;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const TPReport = ({codCollection}) => {
    const [rider, setRider] = useState([])
    const [collectReport, setCollectReport] = useState([])
    const [to, setTo] = useState('')
    const [from, setFrom] = useState('')
    
    useEffect( async() => {
    }, [])

      let collectData = [];
      collectReport?.map(item => ( 
        collectData.push({'Order Id': item?.order_id,'TP name': item?.tp_name, 'Customer': item?.recipient,
          'Merchant': item?.merchant, 'Shop': item?.shop, 'State': item?.state,
        "State": item?.state, "Collected Amount": item?.collected_amount,
        "TP charge": item?.tp_charge,
        "Manager": item?.manager,
        "Collected at": item?.collected_at
        })
        
        ))
        const name= 'tp_collection_' + to + '__' + from
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
    
        const exportCollectedToCSV = () => {
            const ws = XLSX.utils.json_to_sheet(collectData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, name + fileExtension);
        }
    
    const makeLocalTime = (date) => {
        let hours = date.split('T')[1].split(':')[0];
        let minutes = date.split('T')[1].split(':')[1];
        var am = true;
        if (hours > 12) {
            am = false;
            hours -= 12
        } else if (hours == 12) {
            am = false;
        } else if (hours == 0) {
            hours = 12
        }
    return date.split('T')[0] + "\n" + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
    }

      
    
    const onDateChange = async (value, dateString) => {
        setTo(dateString[0])
        setFrom(dateString[1])
        const res = await codCollection(dateString[0],dateString[1])
        setCollectReport(res)
    }
    
    return ( 
        <>
            <Header title="All Reports" subtitle="Third Party" />

            
            
            <Card title="Collected Amount" bordered={false} style={{ width: '100%' }}>
                <Row>
                    <Col xs={24}>
                        <Form.Item name="rider" label="Select date range">
                            <RangePicker  onChange={onDateChange}/>
                        </Form.Item>
                        {collectReport?.length > 0 ? 
                        <Button  onClick={(e) => exportCollectedToCSV()}>Export</Button>
                        :
                        <Button  disabled>No data</Button>
                        }
                        </Col>
                      
                    
                </Row>
            </Card>

        </>
     );
}
 
export default connect(null,{codCollection})(TPReport);