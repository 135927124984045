import React, { Component } from 'react'

import { Button, Col, Empty, Input, Modal, Row, Select , Table, Tag, Switch, Space } from 'antd';
import { connect } from 'react-redux';
import {fetchConfirmed,pickupParcel} from '../../../../action/parcel'
import {fetchDrivers} from '../../../../action/drivers'
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import Header from '../../../../components/header';
import ReactToPrint from "react-to-print";
import bahon from './stickerLogo.png';
import QRCode from 'qrcode.react';
import Barcode from 'react-barcode';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { InvoiceContainer } from './invoice.style';

const { Option } = Select;

class PrintBarCode extends Component {
  state ={
    invoice : JSON.parse(localStorage.getItem('invoice')),
  }
  printDocument() {
    const input = document.getElementById('divToPrint');
    html2canvas(input)
      .then((canvas) => {
        let imgWidth = 208;
        let imgHeight = canvas.height * imgWidth / canvas.width;
        const imgData = canvas.toDataURL('img/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        // pdf.output('dataurlnewwindow');
        pdf.save(JSON.parse(localStorage.getItem('invoice')).order_date.split("T")[0] + "---" + JSON.parse(localStorage.getItem('invoice')).bahon_id)  ;
      })
    ;
  }
  render() {
    const {invoice} = this.props;
    var today = new Date();
    // const date = invoice.order_date.split("T")[0];
    // const t = invoice.order_date.split("T")[1];
    // const time = t.split(".")[0];
    // const hour = (today.getHours() + 24) % 12 || 12
    // const orderDate = new Date(invoice.order_date);
    return(
     <>
    
    <div style={{padding: '1px'}} id="divToPrint">
      
    {invoice.map((item,i) => ( 
      <>
        <InvoiceContainer >
          <Row gutter={2} style={{margin: '0px',padding: '0px'}} >
            <Col  xs={8} style={{borderRight: '1px solid #000'}}>
              <img src={bahon} />
            </Col>
            <Col  xs={8} style={{borderRight: '1px solid #000',paddingLeft: '5px'}}>
              <p style={{fontSize: '10px'}} >{item.shop_name}</p>
              <p style={{fontSize: '10px'}} >{item.shop_mobile}</p>
              <p style={{fontSize: '10px'}} >Invoice : {item.merchant_order_id}</p>
              <p style={{fontSize: '10px'}} >{item?.order_date?.split("T")[0] + ' ' + item?.order_date?.split("T")[1]?.split(".")[0]}</p>
            </Col>
            <Col  xs={8}>
              <p style={{fontSize: '10px',textAlign:'center'}} >Amount to collect</p>
              <h3 style={{textAlign:'center', fontWeight: 'bold'}}>{item.amount_to_collect} &#2547;</h3>
            </Col>
          </Row>
        
        {/* ===========Info== */}
        
          <Row gutter={0} style={{margin: '0px'}}>
              <Col  xs={24}><h5 style={{fontWeight: 'bold'}}>Customer Details</h5></Col>
                <Col  xs={4} style={{padding: '0px'}}><p >Name</p></Col><Col  xs={20}><p style={{fontWeight:'bold'}}>: {item.customer_name?.slice(0,20)}</p></Col>
                <Col  xs={4} style={{padding: '0px'}}><p>Phone</p></Col><Col  xs={20}><p style={{fontWeight:'bold'}}>: {item.customer_phone}</p></Col>
                <Col  xs={4} style={{padding: '0px'}}><p>Address</p></Col><Col  xs={20}><p style={{fontWeight:'bold'}}>: {item.customer_address?.slice(0,120)}</p></Col>
                <br/>
              <Col  xs={24}><h5 style={{fontWeight: 'bold'}}>Product Details</h5></Col>
                <Col  xs={4} style={{padding: '0px'}}><p>Description</p></Col><Col  xs={20}><p>: {item.product_description?.slice(0,50)}</p></Col>
                <Col  xs={4} style={{padding: '0px'}}><p>Items</p></Col><Col item xs={20}> <p>: {item.item}</p></Col>
          </Row>
            
        <Row gutter={0} style={{display: 'flex', justifyContent: 'center', marginTop: '5px'}}>
          <Col xs={8}>
            <QRCode value={item.bahon_id} size={55}/>
          </Col>
          <Col  xs={12}>
            <Barcode  value={item.bahon_id} width='1' height="30" text={item.bahon_id} fontSize="10px" fontWeight='bold'/>
          </Col>
        </Row>
        
        <Row container spacing={1} style={{ fontSize:'10px', fontWeight:'bold', margin: '0px'}}>
          <Col item xs={12}> {localStorage.getItem('user_id')} </Col>
          <Col item xs={12} style={{textAlign:'right'}}>{today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()} </Col>
        </Row>
        </InvoiceContainer>
        </>
      ))
      
      }
            
        </div>
        {/* <button onClick={this.printDocument} style={{cursor: 'pointer', backgroundColor: '#06aa4a', padding: '12px 185px', textDecoration: 'none', color: '#fff'}} >Download</button> */}

  </>
    )
  }
}


// ============================================
class PickupParcels extends Component {
  state = { 
    searchText: '',
    searchedColumn: '',
    data: [],
    weight: '',
    r_no: [],
    drivers: '',
    rider: '',
    visible: false,
    checkStrictly: false,
    mapList: new Map(),
    selectedId: null,
    detailData: [],
    coverageArea: [],
    found: 0,
   } 
    componentDidMount = () => {
        this.getData()
        this.getDrivers()
    };
    getData = async () => {
        const res = await this.props.fetchConfirmed()
        this.setState({data: res?.list, coverageArea: res?.area, found: res?.list?.length})


        for(let i = 0; i< res?.list.length; i++){
          this.state.mapList.set(res?.list[i].order_id, res?.list[i]);
        }
    }
    getDrivers = async () => {
      const res = await this.props.fetchDrivers()
      this.setState({drivers: res})
    }
   
    
    createOption = (label, value) => {
      return { label, value };
    };

    getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div
          style={{
            padding: 8,
          }}
        >
          <Input 
            ref={node => {
                this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={ e => {setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
              this.setState({
              searchText: e.target.value,
              searchedColumn: dataIndex,
              });}}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block',
            }}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? '#1890ff' : undefined,
          }}
        />
      ),
      onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => this.searchInput.select(), 100);
        }
      },
      render: (text) =>
        this.state.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: '#ffc069',
              padding: 0,
            }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });
    
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
        searchText: selectedKeys[0],
        searchedColumn: dataIndex,
        });
    };

    createData = (key,merchant_order_id,store_info,to,city,area,customer_info,charge_info,weight,r_no,order_time,type,details,action) => {
        return {key,merchant_order_id,store_info,to,city,area,customer_info,charge_info,weight,r_no, order_time,type,details,action};
    };

    makeLocalTime = (date) => {
        let mainDate = date.split('T')[0];
        let hours = date.split('T')[1].split(':')[0];
        let minutes = date.split('T')[1].split(':')[1];
        var am = true;
        if (hours > 12) {
            am = false;
            hours -= 12
        } else if (hours == 12) {
            am = false;
        } else if (hours == 0) {
            hours = 12
        }
        return mainDate + " , " + hours+":"+minutes + " " + (am ? "a.m" : "p.m")
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleSubmit = async (selectedId) => {
      const {weight,rider,r_no} = this.state
      if(!rider){
        alert('Select Driver')
      }
      else{
        localStorage.removeItem('invoice')

        let detailData = []
        for(let i =0; i< selectedId.length; i++){
          detailData.push({...this.state.mapList.get(selectedId[i])})
        }
        this.setState({detailData})
        for(  let i =0 ; i < detailData.length ; i++ ){
          const res = await this.props.pickupParcel(detailData[i],rider);
          if(res === 201){
              this.setState({visible: true})
              this.getData()
          }
        }
      }
   
    }
    onDriverChange = async (e) => {
      this.setState({rider: e})
    }
    
    handleOk = () => {
      localStorage.removeItem('invoice')
      this.setState({visible: false})
    };
  
    handleCancel = () => {
      localStorage.removeItem('invoice')
      this.setState({visible: false})
    };

    handleWeight = (e,id) => {
      const {mapList} = this.state
      const selectedData = mapList.get(id)
      selectedData.weight = e
    }

    handleReceipt = (e,id) => {
      const {mapList} = this.state
      const selectedData = mapList.get(id)
      selectedData.receipt_no = e
    }
  render() { 
    
    const { checkStrictly, selectedId } = this.state;
    const columns = [
      {
        title: 'Order ID',
        dataIndex: 'key',
        key: 'key',
        width: '15%',
        ...this.getColumnSearchProps('key'),
      },
      {
        title: 'Invoice Number',
        dataIndex: 'merchant_order_id',
        key: 'merchant_order_id',
        width: '8%',
        ...this.getColumnSearchProps('merchant_order_id'),
      },
      {
        title: 'Store Info',
        dataIndex: 'store_info',
        key: 'store_info',
        width: '20%',
        ...this.getColumnSearchProps('store_info'),
      },
      {
        title: 'Place',
        dataIndex: 'to',
        key: 'to',
        width: '20%',
        ...this.getColumnSearchProps('to'),
      },
      {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        width: '10%',
        ...this.getColumnSearchProps('city'),
      },
      {
        title: 'Area',
        dataIndex: 'area',
        key: 'area',
        width: '10%',
        ...this.getColumnSearchProps('area'),
      },
      {
        title: 'Customer Info',
        dataIndex: 'customer_info',
        key: 'customer_info',
        width: '20%',
        ...this.getColumnSearchProps('customer_info'),
      },
      {
        title: 'Amount to collect',
        dataIndex: 'charge_info',
        key: 'charge_info',
        width: '10%',
      },
      {
        title: 'Weight(gm)',
        dataIndex: 'weight',
        key: 'weight',
        width: '10%',
      },
      {
        title: 'Receipt no',
        dataIndex: 'r_no',
        key: 'r_no',
        width: '10%',
      },
      {
        title: 'Order Time',
        dataIndex: 'order_time',
        key: 'order_time',
        width: '12%',
        ...this.getColumnSearchProps('order_time'),
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: '12%',
        ...this.getColumnSearchProps('type'),
      },
    ];
    const {data, drivers} = this.state
    const rows = [...
      data
        ? data?.map((item,id) => this.createData(
              item.order_id,
              item.merchant_order_id
            , 
              item?.shop?.name + '(' + item?.shop?.mobile_number +')'
          
              ,
                item?.to === 'in_city' ? 
                  <Tag color="blue">{item?.to}</Tag>
                :
                  <Tag color="purple">{item?.to}</Tag>
            ,
              this.state?.coverageArea?.city[item?.recipient_area_code.split('-')[0]-1]?.name
            ,
              this.state?.coverageArea?.city[item?.recipient_area_code.split('-')[0]-1]?.sub_city[item?.recipient_area_code.split('-')[1]-1]?.thana[item?.recipient_area_code.split('-')[2]-1]?.area[item?.recipient_area_code.split('-')[3]-1]?.name
            ,
              item?.recipient_name + '(' + item?.recipient_mobile_number + ')'
            ,
                item?.charges?.amount_to_collect
            ,
            <Input placeholder={item?.weight} id={id} onChange={(e)=>this.handleWeight(e.target.value,item.order_id)} />
            ,
            <Input id={id} name={[item.weight[1]+'receipt_no']}  onChange={(e)=>this.handleReceipt(e.target.value,item.order_id)}  />
            ,
              this.makeLocalTime(item?.created_at)
            , 
              item?.type
            ,
              <Row gutter={12}>
                <Col xs={12}>
                  <h3>Shop</h3>
                  <>
                    <p style={{margin: '2px 0px'}}>{item?.shop?.shop_id}</p>
                    <p style={{margin: '2px 0px'}}>{item?.shop?.name}</p>
                    <p style={{margin: '2px 0px'}}>{item?.shop?.mobile_number}</p>
                    <p style={{margin: '2px 0px'}}>{item?.shop?.address}</p>
                  </>
                </Col>
                <Col xs={12}>
                  <h3>Customer</h3>
                  <p style={{margin: '2px 0px'}}>{item?.recipient_name}</p>
                  <p style={{margin: '2px 0px'}}>{item?.recipient_mobile_number}</p>
                  <p style={{margin: '2px 0px'}}>{item?.recipient_address}</p>
                </Col>
                  {/* + ' Shop Number: ' + item?.shop?.mobile_number + ' Shop Address ' + item?.shop?.address */}
              </Row>
            ,
            
          ))
        : "",
    ];
    const options = [
      drivers
        ? drivers?.map((item) =>
            this.createOption(
              `${item.mobile_number}----` + item.name,
              item.user_id
            )
          )
        : null,
    ];
    
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({selectedId: selectedRowKeys})
      },
    };
    const handleCount = (a,b,c,d) => {
      if(d.currentDataSource.length > 0){
        this.setState({found: d?.currentDataSource?.length})
      }
      else{
        this.setState({found: 0})
      }
    }

    return (
      <>
        
        <Header title="Pickup Parcels" subtitle="Parcel"  name="normal_order" />
        
        <Select
          style={{width: '100%'}}
          size = "large"
          showSearch
          placeholder="Select a rider"
          optionFilterProp="children"
          onChange={this.onDriverChange}
          value={this.state.rider}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {options[0]? 
          options[0]?.map((item,id) => (
            <Option value={item?.value}>{item?.label}</Option>
          ))
        :null}
        </Select>
       
        {localStorage.getItem('invoice') ?
          <Modal title="Invoice" visible={this.state.visible} okButtonProps={{ style: { display: 'none' } }}  onCancel={this.handleCancel} width="auto">
            <div style={{width:'30%',margin: 'auto', }} >
            <span onClick={() => {this.setState({receipt_no:''})}}>
              <ReactToPrint
              trigger={() => <Button type="primary" style={{width: '100%'}} >Print this out!</Button>}
              content={() => this.PrintBarCode}
            />
            </span>
              <PrintBarCode invoice={JSON.parse(localStorage.getItem('invoice'))} ref={(el) => (this.PrintBarCode = el)} />
            <span onClick={() => {this.setState({receipt_no:''})}}>
              <ReactToPrint
              trigger={() => <Button type="primary" style={{width: '100%'}} >Print this out!</Button>}
              content={() => this.PrintBarCode}
            />
            </span>
              <br/>
              <br/>
              <br/>
              <br/>
              {/* <p onClick={this.printDocument}>Download</p> */}
              {/* <button onClick={this.printDocument}>Print</button> */}
            </div>
          </Modal>
        
        : null}
        <br />
        <br />
        {selectedId ?
          <Button size="large" type="primary" style={{width: '100%'}} onClick={()=> this.handleSubmit(selectedId)}>Pick UP</Button>
        :null}
        <br />
        {
          this.state.found > 0 ?
          <p style={{textAlign: 'right', padding: '10px'}}> <Tag color="blue">{this.state.found} data found</Tag>  </p>
        : 
          <p style={{textAlign: 'right', padding: '10px'}}> <Tag color="red">No data found</Tag>  </p>
        }
            {rows?.length > 0
            ? 
              <>
                <Table columns={columns} dataSource={rows} scroll={{ x: 1000 }} sticky 
                  rowClassName={(record) => record?.type == 'NO' ? 'table-row-light' :  'red'} 
                  rowkey={(record) => console.log(record.key)} 
                  rowSelection={{ ...rowSelection, checkStrictly }}
                  expandable={{
                    expandedRowRender: record => <p style={{ margin: 0 }}>{record.details}</p>,
                    expandRowByClick: true
                    
                  }}
                  onChange={(pagination, filters, sorter, currentDataSource ) => handleCount(pagination, filters, sorter, currentDataSource )}
                />
                {selectedId ?
                  <Button size="large" type="primary" style={{width: '100%'}} onClick={()=> this.handleSubmit(selectedId)}>Pick UP</Button>
                :null}
              </>
            : 
            <Empty />}

      </>
    );
  }
}

export default connect(null,{fetchConfirmed,fetchDrivers,pickupParcel})(PickupParcels);
