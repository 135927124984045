
import React, { Component } from 'react'
import styled from 'styled-components';
import loader from './loader.gif';
const Container = styled.div`
    position: absolute;
    /* background-color: #74b9ff; */
    width: 100%;
    height: 100vh;
    top:0px;
    z-index: 1000;
    
`;
const ImgContainer = styled.div`
    position: absolute;
    /* background-color: rgba(127, 143, 166,.8); */
    top:40%;
    left: 40%;
    z-index: 1000;
    
    
`;
const Img = styled.img`

`;
class Spinner extends Component {
    state = {  }
    render() { 
        return ( 
            <Container>
                <ImgContainer>
                <Img src={loader} alt="Loader"/>
            </ImgContainer>
            </Container>
         );
    }
}
 
export default Spinner;