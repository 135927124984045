import logo from './logo.svg';
import "antd/dist/antd.css";
import './App.css';
import { Router, Route,Switch, Redirect } from 'react-router-dom';
import history from './history';
import Layout from './layout';
import Login from './pages/login';

function App() {
  return (
    <Router history={history} >
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/" component={Layout} />
      </Switch>
    </Router>
  );
}

export default App;
